import { createAction, handleActions, combineActions } from 'redux-actions';
// - Actions
export const searchGuidesRequest = createAction('GUIDES_LIST_SEARCH_REQUEST');
export const searchGuidesSuccess = createAction('GUIDES_LIST_SEARCH_SUCCESS');

export const createGuidesRequest = createAction('GUIDES_CREATE_REQUEST');
export const createGuidesSuccess = createAction('GUIDES_CREATE_SUCCESS');

export const updateGuidesRequest = createAction('GUIDES_UPDATE_REQUEST');
export const updateGuidesSuccess = createAction('GUIDES_UPDATE_SUCCESS');

export const deleteGuidesRequest = createAction('GUIDES_DELETE_REQUEST');
export const deleteGuidesSuccess = createAction('GUIDES_DELETE_SUCCESS');

export const getTreeGuidesRequest = createAction('GET_TREE_GUIDE_REQUEST');
export const getTreeGuidesSuccess = createAction('GET_TREE_GUIDE_SUCCESS');

export const getParentGuidesRequest = createAction('GET_PARENT_GUIDE_REQUEST');
export const getParentGuidesSuccess = createAction('GET_PARENT_GUIDE_SUCCESS');

export const getGuidesPopularRequest = createAction('GET_GUIDE_POPULAR_REQUEST');
export const getGuidesPopularSuccess = createAction('GET_GUIDE_POPULAR_SUCCESS');

export const getGuidesRequest = createAction('GET_GUIDE_REQUEST');
export const getGuidesSuccess = createAction('GET_GUIDE_SUCCESS');

export const getGuideByIdRequest = createAction('GET_GUIDE_BY_ID_REQUEST');
export const getGuideByIdSuccess = createAction('GET_GUIDE_BY_ID_SUCCESS');

export const failure = createAction('GUIDES_ACTION_FAILURE');


// - Initial State
export const initialState = {
  guides: [],
  pagination: {
    size: 1,
    page: 50,
    total: 0
  },
  loading: false,
  guide: null,

  showMessage: false,
  parentGuides: [],
  loadingFetch: false,
  
  mainGuides: [],
  mainLoading: false,
  
  trees: [],
  treesing: false,
  
  populars: [],
  popularsing: false,
  error: null,
};

// - Reducers
const reducer = handleActions({
  [combineActions(createGuidesRequest, deleteGuidesRequest, updateGuidesRequest, searchGuidesRequest)]: (state) => ({
    ...state, loading: true
  }),
  [searchGuidesSuccess]: (state, { payload: { guides, pagination } }) => {
    return { ...state, guides: guides, pagination, loading: false };
  },
  [combineActions(createGuidesSuccess, deleteGuidesSuccess, updateGuidesSuccess)]: (state) => ({
    ...state, loading: false
  }),

  [getTreeGuidesRequest]: (state) => {
    return { ...state, trees: [], treesing: true };
  },
  [getTreeGuidesSuccess]: (state, { payload: { trees } }) => {
    return { ...state, trees: trees, treesing: false };
  },

  [getParentGuidesRequest]: (state) => {
    return { ...state, parentGuides: [], loadingFetch: true };
  },
  [getParentGuidesSuccess]: (state, { payload: { guides } }) => {
    return { ...state, parentGuides: guides, loadingFetch: false };
  },

  [getGuidesPopularRequest]: (state) => {
    return { ...state, populars: [], popularsing: true };
  },
  [getGuidesPopularSuccess]: (state, { payload: { guides } }) => {
    return { ...state, populars: guides, popularsing: false };
  },

  [getGuidesRequest]: (state) => {
    return { ...state, mainGuides: [], mainLoading: true };
  },
  [getGuidesSuccess]: (state, { payload: { guides } }) => {
    return { ...state, mainGuides: guides, mainLoading: false };
  },

  [getGuideByIdRequest]: (state) => {
    return { ...state, guide: null};
  },
  [getGuideByIdSuccess]: (state, { payload: { guide } }) => {
    return { ...state, guide: guide };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loadingFetch: false,
    loading: false,
  }),
}, initialState);

export default reducer;
