import { call, put, takeLatest } from 'redux-saga/effects';
// import { authenService } from '../../services';
import * as AuthService from '../../services/authen-service';
import { LoginFormAction, RegisterFormAction, LoginPhoneFormAction, ForgetFormAction } from './action';
import { SubmissionError } from 'redux-form';
import Auth from '../../storage/Auth'


export function* LoginLocal({ payload }) {
  try {
    const { code, data, message } = yield call(AuthService.login, payload);
    if (code && data && data.access_token) {
      Auth.authenticateUser(data.access_token);
      yield put(LoginFormAction.success("Lưu thành công"));
    } else {
      const formError = new SubmissionError({
        _error: message || "Đăng nhập không thành công!"
      });
      Auth.deauthenticateUser();
      yield put(LoginFormAction.failure(formError));
    }
  } catch (error) {
    let message = (error && error.message) || 'Đăng nhập không thành công!'
    if(error && error.error_description === 'invalid_username_or_password') {
      message = "Tên đăng nhập hoặc mật khẩu không chính xác";
    }
    Auth.deauthenticateUser();
    const formError = new SubmissionError({_error: message});
    yield put(LoginFormAction.failure(formError));
  }
}

export function* RegisterLocal({ payload }) {
  try {
    const { message, data} = yield call(AuthService.register, payload);
    if (data && data.token) {
      yield put(RegisterFormAction.success(payload.username));
    } else {
      const _message = message || "Đăng ký thất bại!";
      const formError = new SubmissionError({_error: _message});
      yield put(RegisterFormAction.failure(formError));
    }
  } catch (error) {
    const message = (error && error.message) || 'Đăng ký thất bại'
    const formError = new SubmissionError({_error: message});
    yield put(RegisterFormAction.failure(formError));
  }
}

export function* ForgetLocal({ payload }) {
  try {
      const { Code, Message } = yield call(AuthService.resetPassword, payload);
      if (Code === 0) {
        yield put(ForgetFormAction.success());
      } else {
        const message = Message || "Đặt lại mật khẩu không thành công!";
        const formError = new SubmissionError({
          _error: message
        });
        yield put(ForgetFormAction.failure(formError));
      }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error.Message || 'Đặt lại mật khẩu không thành công!'
    });
    yield put(ForgetFormAction.failure(formError));
  }
}

export function* getCodePassword({ payload = {} }) {
  const { data, cb } = payload;
  try {
    const {Code, Message } = yield call(AuthService.forgotPassword, data);
    if (Code === 0  && cb) cb('success', Message);
    if (Code ===-1  && cb) cb('error', Message);
  } catch (error) {
    if (cb) cb('error')
  }
}


export default [
  takeLatest(LoginFormAction.REQUEST, LoginLocal),
  takeLatest(RegisterFormAction.REQUEST, RegisterLocal),
  takeLatest(ForgetFormAction.REQUEST, ForgetLocal),
  takeLatest('GET_CODE_VERIFY_PASS', getCodePassword),
];
