import { combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import { reducer as oidcReducer } from 'redux-oidc';

import authReducer from './auth-redux/reducer';
import commonReducer from './common-redux/reducer';
import profileReducer from './profile-redux/reducer';
import language from './language-redux/reducer';
import guideReducer from "./guide-redux/reducer";
import usersReducer from "./users-redux/reducer";


export default (history, injectedReducers) => combineReducers({
    // routing: routerReducer,
    form: formReducer,
    oidc: oidcReducer,
    router: connectRouter(history),
    auth: authReducer,
    common: commonReducer,
    profile: profileReducer,
    systemLanguage: language,
    guide: guideReducer,
    users: usersReducer,
    ...injectedReducers,
  });
