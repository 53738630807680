import { createAction, handleActions } from 'redux-actions';
// - Actions
export const changeLanguage = createAction('CHANGE_LANGUAGE');

// - Initial State
export const initialState = {
    language: 'vi'
};

// - Reducers
const reducer = handleActions({
    [changeLanguage]: (state, { payload : { language } }) => {
        return {
        ...state,
        language
        };
    }
}, initialState);

export default reducer;
