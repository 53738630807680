import { call, put, takeLatest } from "redux-saga/effects";
import * as userService from "../../services/user-service";
import Auth from '../../storage/Auth';
import { Modal } from 'antd';
import { ProfileFormAction, PassFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  getProfileSuccess,
  getAvatarSuccess,
  failure
} from "./reducer";

const notifyProfile = (notify) => {
  if(notify) return null;
  Modal.error({
    title: 'Lỗi tài khoản!',
    content: 'Đã có lỗi xảy ra khi lấy thông tin tài khoản. Vui lòng liên hệ PCSPOST 1900 599 838 để được hỗ trợ!',
    okCancel: false,
    okText: 'Thoát',
    onOk() {
      Auth.deauthenticateUser();
      window.location.href = "/login";
    },
    onCancel() {
    }
  });
}

export function* getProfile({payload}) {
  try {
    const { data } = yield call(userService.getProfileByToken);
    if (data && data.user) {
      const user = data.user;
      user.admin = user.permissions === 'superadmin' || user.permissions === 'admin';
      yield put(getProfileSuccess({ user }));
    } else {
      yield put(getProfileSuccess({ user: null }));
      notifyProfile(payload.notify);
    }
  } catch (error) {
    yield put(getProfileSuccess({ user: null }));
    notifyProfile(payload.notify);
  }
}

export function* getAvatar({ payload = {} }) {
  try {
    const { code } = yield call(userService.getAvatarById, payload.id);
    if (code) {
      yield put(getAvatarSuccess({ avatar: code }));
    } else {
      yield put(failure("Get avatar failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* saveProfile({ payload }) {
  try {
    let { message, code } = yield call(userService.updateProfile, {user: payload, id: payload.id });
    if (code) {
      yield put(ProfileFormAction.success("Cập nhập thành công"));
    } else {
      const formError = new SubmissionError({
        _error: message || "Cập nhập thất bại!"
      });
      yield put(ProfileFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: (error && error.message) || 'Cập nhập thất bại!'
    });
    yield put(ProfileFormAction.failure(formError));
  }
}

export function* changePassword({ payload }) {
  try {
    const {code, message} = yield call(userService.changePassword, payload);
    if (code) {
      yield put(PassFormAction.success("Thay đổi thành công"));
    } else {
      const formError = new SubmissionError({
        _error: message ||  "Thay đổi không thành công!"
      });
      yield put(PassFormAction.failure(formError));
    }
  } catch (error) {
    let messsage = 'Thay đổi không thành công!'
    if(error && error.messsage && typeof error.messsage === 'string') {
      messsage = error.messsage;
    }
    const formError = new SubmissionError({
      _error: messsage
    });
    yield put(PassFormAction.failure(formError));
  }
}

export default [
  takeLatest(ProfileFormAction.REQUEST, saveProfile),
  takeLatest(PassFormAction.REQUEST, changePassword),
  takeLatest("GET_PROFILE_REQUEST", getProfile),
  takeLatest("GET_AVATAR_REQUEST", getAvatar),
  
];
