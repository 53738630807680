import vi from './svg/flags/vietnam.svg';
import en from'./svg/flags/united-kingdom.svg';
import flas_jp from'./flags/jp.svg';
import flas_vi from'./flags/vi.svg';
import flas_en from'./flags/uk.svg';


import logo from'./icons/logo.png';
import logo_pcs from'./icons/logo_pcs.png';
import logo_large from'./icons/banner-login.png';
import loading from'./svg/loading.svg';
import home from'./svg/home.svg';
import filter from'./svg/filter-results.svg';

import system_1 from'./svg/system/dv_1.svg';
import system_2 from'./svg/system/dv_2.svg';
import system_3 from'./svg/system/dv_3.svg';
import system_4 from'./svg/system/dv_4.svg';
import system_5 from'./svg/system/dv_5.svg';
import system_6 from'./svg/system/dv_6.svg';

const icons = {
  common: {
    logo: logo,
    logo_pcs: logo_pcs,
    logo_large: logo_large,
    loading: loading,
    home: home,
    filter: filter,
    list_task: require('./svg/list.svg'),
    excel: require('./svg/excel.svg'),
    excel_primary: require('./svg/excel-primary.svg'),
    more: require('./svg/more.svg'),
    // order: require('./svg/order.svg'),
    exchange: require('./svg/exchange.svg'),
    email: require('./svg/email.svg'),
    sms: require('./svg/sms.svg'),
    optin: require('./svg/optin.svg'),
    report_week: require('./svg/report_w.svg'),

    next: require('./svg/next.svg'),

    tiendatra: require('./icons/tiendatra.png'),
    tienchuatra: require('./icons/tienchuatra.png'),
    tienchotra: require('./icons/tienchotra.png'),

    print: require('./svg/print.svg'),
    back: require('./svg/back.svg'),
    up_arrow: require('./svg/up-arrow.svg'),
    down_arrow: require('./svg/down-arrow.svg'),
    import: require('./svg/import.svg'),
    export: require('./svg/export.svg'),
    refresh: require('./svg/refresh.svg'),
    history: require('./svg/history.svg'),

    trace: require('./svg/dang_giao.svg'),
  },
  flag: {
    jp_icon: flas_jp,
    vi_icon: flas_vi,
    en_icon: flas_en,
  },
  system: {
    system_1,
    system_2,
    system_3,
    system_4,
    system_5,
    system_6,
  },
  file: {
    pdf: require('./images/file/pdf.svg'),
    doc: require('./images/file/doc.svg'),
    xlsx: require('./images/file/xls.svg'),
    png: require('./images/file/png.svg'),
  },
  post: {
    trongnuoc: require('./svg/post/icon_trongnuoc.svg'),
    quocte: require('./svg/post/icon_quocte.svg'),
    danhgia: require('./svg/post/icon_danhgia.svg'),
    quanlydon: require('./svg/post/icon_qldonhang.svg'),
    doanhthu: require('./svg/post/icon_thongke.svg'),
    yclayhang: require('./svg/post/icon_yclayhang.svg'),
  },

  NationalFlag: {
    vi: vi,
    en: en
  }
}

const images = {
  common: {
    noAvatar: require('./images/no-avatar.png'),
    noImage: require('./images/no-image-big.jpg')
  },
  home: {

  },
  aboutUs: {

  }
}

export {
  icons,
  images
}
