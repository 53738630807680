import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';
import { APP_CONFIG } from '../config';
import localStorage from 'localStorage'

const userManagerConfig = {
  client_id: 'pcs-docs',
  authority: APP_CONFIG.URL_LOGIN,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  post_logout_redirect_uri : `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
  response_type: 'token id_token',
  scope: 'openid profile crm_api.read_only',
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: false,
  silentRequestTimeout: 50000,
  revokeAccessTokenOnSignout: true,
  userStore: new WebStorageStateStore({ store: localStorage })
};

const userManager = new createUserManager(userManagerConfig);

export default userManager;
