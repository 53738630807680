import Loadable from 'react-loadable';
import Loading from '../components/Loading'

const LoginPage = Loadable({loader: () => import(/*webpackChunkName:'login'*/'../auth/login'), loading: Loading});
const CallbackPage = Loadable({loader: () => import(/*webpackChunkName:'callback'*/'../auth/callback'), loading: Loading});
// const LoginPage = Loadable({loader: () => import(/*webpackChunkName:'login'*/'../pages/auth/LoginPage'), loading: Loading});
// const RegisterPage = Loadable({loader: () => import(/*webpackChunkName:'register'*/'../pages/auth/RegisterPage'), loading: Loading});
// const ForgetPage = Loadable({loader: () => import(/*webpackChunkName:'forgot'*/'../pages/auth/ForgetPage'), loading: Loading});

const MainView = Loadable({loader: () => import(/*webpackChunkName:'views'*/'../views'), loading: Loading});
const HomeView = Loadable({loader: () => import(/*webpackChunkName:'home'*/'../views/home/HomeVM'), loading: Loading});
const SearchView = Loadable({loader: () => import(/*webpackChunkName:'home'*/'../views/search/SearchVM'), loading: Loading});
const GuideView = Loadable({loader: () => import(/*webpackChunkName:'guideview'*/'../views/guide/GuideVM'), loading: Loading});

const MainPage = Loadable({loader: () => import(/*webpackChunkName:'mains'*/'../pages'), loading: Loading});
const DashboardPage = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/dashboard/dashboardVM'), loading: Loading});
const GuidePage = Loadable({loader: () => import(/*webpackChunkName:'guide'*/'../pages/guide/GuideVM'), loading: Loading});
const GuidesPage = Loadable({loader: () => import(/*webpackChunkName:'guides'*/'../pages/guide/GuidesVM'), loading: Loading});
const UsersPage = Loadable({loader: () => import(/*webpackChunkName:'guides'*/'../pages/users/UsersVM'), loading: Loading});
const ProfilePage = Loadable({loader: () => import(/*webpackChunkName:'profile'*/'../pages/user/ProfilePage/ProfileVM'), loading: Loading});
const PasswordPage = Loadable({loader: () => import(/*webpackChunkName:'passwords'*/'../pages/user/PasswordPage/PasswordVM'), loading: Loading});

const routes = [
  // { exact: true, path: "/login", component: LoginPage },
  // { exact: true, path: "/quen-mat-khau", component: ForgetPage },
  // { exact: true, path: "/dang-ky", component: RegisterPage },
  { exact: true, path: "/login", component: LoginPage },
  { exact: true, path: "/callback", component: CallbackPage },
  {
    exact: false,
    path: "/admin",
    component: MainPage,
    private: true,
    routes: [
      { exact: true, path: "/admin", component: DashboardPage },
      { exact: true, path: "/admin/guide", component: GuidePage },
      { exact: true, path: "/admin/guide/:id", component: GuidePage },
      { exact: true, path: "/admin/guides", component: GuidesPage },
      { exact: true, path: "/admin/users", component: UsersPage},
      { exact: true, path: "/admin/user", component: ProfilePage},
      { exact: true, path: "/admin/user/password", component: PasswordPage},
    ]
  },
  {
    exact: false,
    path: "/:code/guide",
    component: MainView,
    routes: [
      { exact: true, path: "/:code/guide", component: GuideView },
      { exact: true, path: "/:code/guide/:slug", component: GuideView },
    ]
  },
  { exact: true, path: "/search", component: SearchView },
  { exact: true, path: "/", component: HomeView },
];

export default routes
