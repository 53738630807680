
import axios from '../utils/axiosInstance';

export const findGuides = async (params) => {
  try {
    const { data } = await axios.get('/api/guides', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGuides = async (params) => {
  try {
    const { data } = await axios.get('/api/guides/get', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTreeGuides = async (payload) => {
  try {
    const { data } = await axios.get(`/api/guides/tree`, { params: payload });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getParentGuides = async (parent = null) => {
  try {
    const { data } = await axios.get(`/api/guides/parent/${parent}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGuidesPopular = async (parent = null) => {
  try {
    const { data } = await axios.get('/api/guides/popular');
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGuideById = async (id) => {
  try {
    const { data } = await axios.get(`/api/guide/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGuideBySlug = async (slug) => {
  try {
    const { data } = await axios.get(`/api/guide/slug/${slug}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createGuides = async (guide) => {
  try {
    const { data } = await axios.post('/api/guide', guide);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateGuides = async ({guide, id}) => {
  try {
    delete guide.id;
    const { data } = await axios.put(`/api/guide/${id}`, guide);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteGuides = async (id) => {
  try {
    const { data } = await axios.delete(`/api/guide/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
