import axiosIns from "../utils/axiosInstance";
import axios from 'axios';


export const login = async dataLogin => {
  try {
    const { data } = await axiosIns.post("/api/auth/signin", dataLogin);
    return data;
  } catch (error) {
    throw error;
  }
};

export const loginSocial = async dataLogin => {
  try {
    const { data } = await axios.post("/api/auth/loginSocial", dataLogin);
    return data;
  } catch (error) {
    throw error;
  }
};

export const loginPhone = async dataLogin => {
  try {
    const { data } = await axios.post("https://id.pcs.vn/api/account/token-phone/by-opt", dataLogin);
    return data;
  } catch (error) {
    throw error;
  }
};

export const refreshToken = async token => {
  const formData = new FormData();
  formData.append('client_id', 'pcs-post');
  formData.append('client_secret', 'pcs@2020!');
  formData.append('grant_type', 'refresh_token');
  formData.append('refresh_token', token);
  formData.append('scopes', 'openid offline_access crm_api.readonly');
  try {
    const { data } = await axiosIns.post("https://id.pcs.vn/connect/token", formData);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCodePhone = async ({PhoneNr}) => {
  try {
    const { data } = await axios.get(`https://id.pcs.vn/api/account/get-otp/phone?PhoneNr=${PhoneNr}`);
    return data;
  } catch (error) {
    throw error;
  }
};



export const register = async dataLogin => {
  try {
    const { data } = await axiosIns.post(`/api/auth/register/user`, dataLogin);
    return data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async ({EmailOrPhone}) => {
  try {
    const { data } = await axios.get(`https://id.pcs.vn/api/account/reset-password?EmailOrPhone=${encodeURIComponent(EmailOrPhone)}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (dataReset) => {
  try {
    const { data } = await axios.post(`https://id.pcs.vn/api/account/reset-password`, dataReset);
    return data;
  } catch (error) {
    throw error;
  }
};
