import { all } from "redux-saga/effects";
import authSaga from "./auth-redux/saga";
import commonSaga from './common-redux/saga';
import profileSaga from "./profile-redux/saga";
import guideSaga from "./guide-redux/saga";
import usersSaga from "./users-redux/saga";


export default function* rootSaga(getState) {
  yield all([
    ...authSaga,
    ...commonSaga,
    ...profileSaga,
    ...guideSaga,
    ...usersSaga,
  ]);
}
