import { createFormAction } from "redux-form-saga";

export const FORM_KEY = "USER_INFO_FORM";
export const ProfileFormAction = createFormAction(FORM_KEY);

export const FORM_CHANGE_KEY = "FORM_PASSWORD_FORM";
export const PassFormAction = createFormAction(FORM_CHANGE_KEY);



