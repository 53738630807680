import React from "react";
import { withRouter } from "react-router-dom";
import { BackTop } from 'antd';

const style = {
  position: "fixed",
  right: "20px",
  bottom: "75px",
  backgroundColor: "#065db1",
  cursor: "pointer",
  textAlign: 'center',
  lineHeight: '35px',
  height: "35px",
  width: "35px",
  borderRadius: "2px",
  opacity: .75
};

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <BackTop visibilityHeight={100}>
        <div style={style}>
          <i className="fa fa-chevron-up fa-lg" style={{color: "#fff"}}/>
        </div>
      </BackTop>
    );
  }
}

export default withRouter(ScrollToTop);
