import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import routes from './routers';
import RouteSubRoutes from './RouteSubRoutes';
import ScrollTop from './ScrollTop';
import Loadable from 'react-loadable';
import Loading from '../components/Loading';
import ImageGallery from './../components/ImageGallery';

const ErrorPage = Loadable({loader: () => import(/*webpackChunkName:'guides'*/'../views/404/404'), loading: Loading});

class App extends Component {
  render() {
    return (
      <div>
        <Switch>
            {routes.map((route, i) => <RouteSubRoutes key={i} {...route} />)}
            <Route render={props => <ErrorPage {...props} />}/>
        </Switch>
        <ScrollTop/>
        <ImageGallery/>
      </div>
    )
  }
}

export default App
