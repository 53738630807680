import { createAction, handleActions } from "redux-actions";
// - Actions
export const getProfileRequest = createAction("GET_PROFILE_REQUEST");
export const getProfileSuccess = createAction("GET_PROFILE_SUCCESS");

export const getAvatarRequest = createAction("GET_AVATAR_REQUEST");
export const getAvatarSuccess = createAction("GET_AVATAR_SUCCESS");

export const deleteAvatarRequest = createAction("DEL_AVATAR_REQUEST");

export const failure = createAction("PROFILE_ACTION_FAILURE");

// - Initial State
export const initialState = {
  avatar: null,

  user: null,
  usering: false,

  error: null
};

// - Reducers
const reducer = handleActions(
  {
    [getProfileRequest]: state => ({
      ...state, usering: true
    }),
    [getProfileSuccess]: (state, { payload: { user } }) => ({
      ...state, user: user, usering: false
    }),

    [getAvatarRequest]: state => ({
      ...state, avatar: null
    }),
    [getAvatarSuccess]: (state, { payload: { avatar } }) => ({
      ...state, avatar: avatar
    }),

    [failure]: (state, action) => ({
      ...state,
      loading: false,
      contacting: false,
      error: action.error
    })
  },
  initialState
);

export default reducer;
