import { call, put, takeLatest } from 'redux-saga/effects';
import * as guideService from '../../services/guide-service';

import { GuideFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  deleteGuidesSuccess,
  searchGuidesRequest,
  searchGuidesSuccess,
  updateGuidesSuccess,
  getTreeGuidesSuccess,
  getParentGuidesSuccess,
  getGuidesPopularSuccess,
  getGuidesSuccess,
  getGuideByIdSuccess,
  failure,
  getTreeGuidesRequest,
} from './reducer';


export function* fetchGuides({ payload = {} }) {
  try {
    const { data } = yield call(guideService.findGuides, payload);
    if(data) {
      const { results, pagination} = data;
      yield put(searchGuidesSuccess({ guides: results, pagination }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* updateGuides({ payload }) {
  try {
    const result = yield call(guideService.updateGuides, payload);
    if (result) {
      yield put(updateGuidesSuccess());
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* createGuides({ payload }) {
  try {
      let result;
      if (payload.id) {
        result = yield call(guideService.updateGuides, { guide: payload, id: payload.id});
      } else {
        result = yield call(guideService.createGuides, payload);
      }
    if (result) yield put(GuideFormAction.success('app.editSuccess'))
    else {
      const message = 'Add photo failed'
      const formError = new SubmissionError({
        _error: message
      })

      yield put(GuideFormAction.failure(formError))
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error:  error.message || 'Save failed'
    })

    yield put(GuideFormAction.failure(formError))
  }
}

export function* deleteGuides({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(guideService.deleteGuides, id);
    if (result) {
      yield put(searchGuidesRequest());
      yield put(deleteGuidesSuccess());
      if(cb) cb('success');
    }
  } catch (error) {
    yield put(failure(error));
    if(cb) cb('error');
  }
}

export function* getTreeGuides({ payload = {} }) {
  try {
    const { data } = yield call(guideService.getTreeGuides, payload);
    yield put(getTreeGuidesSuccess({ trees: data || [] }));
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getParentGuides({ payload = {} }) {
  try {
    const { id } = payload;
    const { data } = yield call(guideService.getParentGuides, id);
    yield put(getParentGuidesSuccess({ guides: data || [] }));
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getGuidesPopular({ payload = {} }) {
  try {
    const { data } = yield call(guideService.getGuidesPopular);
    if(data && data.results) {
      const { results } = data;
      yield put(getGuidesPopularSuccess({ guides: results }));
    } else {
      yield put(getGuidesPopularSuccess({ guides: [] }));
    }
  } catch (error) {
    yield put(getGuidesPopularSuccess({ guides: [] }));
  }
}

export function* getGuides({ payload = {} }) {
  try {
    const { data } = yield call(guideService.getGuides, payload);
    if(data && data.guides) {
      yield put(getGuidesSuccess({ guides: data.guides }));
    }

  } catch (error) {
    yield put(failure(error));
  }
}

export function* getGuideById({ payload = {} }) {
  try {
    const { data } = yield call(guideService.getGuideById, payload.id);
    if(data && data._id) {
      yield put(getGuideByIdSuccess({ guide: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export default [
  takeLatest(GuideFormAction.REQUEST, createGuides),
  takeLatest('GUIDES_LIST_SEARCH_REQUEST', fetchGuides),
  takeLatest('GUIDES_DELETE_REQUEST', deleteGuides),
  takeLatest('GUIDES_UPDATE_REQUEST', updateGuides),
  takeLatest('GET_TREE_GUIDE_REQUEST', getTreeGuides),
  takeLatest('GET_PARENT_GUIDE_REQUEST', getParentGuides),
  takeLatest('GET_GUIDE_POPULAR_REQUEST', getGuidesPopular),
  takeLatest('GET_GUIDE_REQUEST', getGuides),
  takeLatest('GET_GUIDE_BY_ID_REQUEST', getGuideById),
];
