import localStorage from 'localStorage'
import jwt from 'jsonwebtoken';

class Auth {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static authenticateUser (token) {
    localStorage.setItem('token', token)
  }

  static setTokenRefresh (token) {
    localStorage.setItem('token_refresh', token)
  }

  static setUrlRedirect (url) {
    localStorage.setItem('url_redirect', url)
  }

  static setLanguage (lang) {
    localStorage.setItem('lng', lang)
  }

  static getLanguage () {
    return localStorage.getItem('lng') || 'vi'
  }


  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated () {
    const token = this.getToken();
    if(token) {
      var user = jwt.decode(token);
      return (user && user.exp > 0 && Date.now() <= user.exp * 1000)
    }
    return false;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser () {
    localStorage.removeItem('token')
    localStorage.removeItem('token_refresh')
    localStorage.removeItem('url_redirect')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('store')
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken () {
    return localStorage.getItem('token')
  }

  static getTokenRefresh () {
    return localStorage.getItem('token_refresh')
  }

  static getUrlRedirect () {
    return localStorage.getItem('url_redirect')
  }

  static removeUrlRedirect () {
    localStorage.removeItem('url_redirect')
  }

  static setUrlNext (url) {
    localStorage.setItem('url_next', url)
  }


  static getUrlNext () {
    return localStorage.getItem('url_next')
  }

  /**
   * Set userinfo. Save userinfo in Local Storage
   *
   * @param {string} user
   */
  static setUserInfo (user) {
    localStorage.setItem('userInfo', user)
  }

  /**
   * Get userinfo.
   *
   * @returns {string}
   */

  static getUserInfo () {
    return localStorage.getItem('userInfo')
  }

  /**
   *Set station.
   *
   * @returns {string}
   */

  static setStation (station) {
    localStorage.setItem('station', station);
  }

  /**
   * Get station.
   *
   * @returns {string}
   */

  static getStation () {
    return localStorage.getItem('station');
  }

  static getUser () {
    try {
      const token = this.getToken();
      var user = jwt.decode(token);
      if(user) {
        return user;
      } 
      return null;
    } catch (error) {
      return null;
    }
  }
}

export default Auth
