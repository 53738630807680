import axios from 'axios';
import { Modal } from 'antd';
import { apiEndpoint } from '../config';
import Auth from '../storage/Auth';
import store from "../reduxs/store";

var showLogout = true;
const logout = () => {
  Auth.deauthenticateUser();
  window.location.href = "/login";
}

const axiosInstance = axios.create({
  baseURL: `${apiEndpoint}`,
  timeout: 120000,
});

axiosInstance.interceptors.request.use(
    (config) => {
      const lng = Auth.getLanguage();
      // config.headers.authorization = `Bearer ${token}`;
      const oidc = store.getState().oidc;
      if(oidc && oidc.user && oidc.user.access_token) {
        const token = oidc.user.access_token;
        config.headers.authorization = `Bearer ${token}`;
      }
      if (config.url.startsWith('/api/')) {
        config.headers['Cache-Control'] = 'no-cache';
      }
      config.headers.lang = lng || 'vi';
      return config;
    },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      const { data, status } = error.response;
      if (data || status) {
          if((data.Message === 'UNAUTHORIZED' || status === 401) && showLogout) {
            showLogout = false;
            Modal.info({
              title: 'Phiên làm việc đã hết hạn!',
              content: 'Phiên làm việc hiện tại đã hết hạn. Xin vui lòng đăng nhập để tiếp tục sử dụng.',
              okText: 'Đăng nhập',
              onOk() {
                showLogout = true;
                logout()
              },
            });
          }
          // else if(data.StatusCode === 500 && showLogout) {
          //   showLogout = false;
          //   Modal.error({
          //     title: 'Lỗi!',
          //     content: 'Đã có lỗi xảy ra khi xử lý yêu cầu. Vui lòng thử lại!',
          //     okCancel: true,
          //     okText: 'Đóng',
          //     cancelText: 'Thử lại',
          //     onOk() {
          //       showLogout = true;
          //     },
          //     onCancel() {
          //       showLogout = true;
          //       window.location.reload();
          //     }
          //   });
          // }
          return Promise.reject(data)
      }
    }
    return Promise.reject(error)
  })

export default axiosInstance;
