import { Icons } from './../assets';

export const apiEndpoint = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8080';

export const title = 'REPORT';
export const ApiUploadImage = `${apiEndpoint}/api/upload/file`;
export const ApiUploadAvatar = `/api/user-info/avatar`;
export const getUrlAvatar = (accounNr) => `${apiEndpoint}/api/user-info/${accounNr}`;
export const URL_ROOT = `${apiEndpoint}/`;
export const auth = {
  appFacebookId: '637698013405871',
  clientIdGoogle: '408369692449-l7jqfvaqe5h0t0qfd8edbfha5ua2hg03.apps.googleusercontent.com'
};

const maxBirthday = () => {
  let maxDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() - 16)
  return maxDate
}

const minBirthday = () => {
  let maxDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() - 120)
  return maxDate
}

export const APP_CONFIG = {
  googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBL5OndUGR3MZoGH3slGBrYD1QyNyUMrhk&v=3.exp&libraries=geometry,drawing,places",
  URL_LOGIN: 'https://id.pcs.vn',
  clientId: 'pcs-system',

  BIRTHDAY: {
    MIN_BIRTHDAY: minBirthday(),
    MAX_BIRTHDAY: maxBirthday()
  },

  PERMISSIONS: [
    { key: 1, text: "Admin", value: "admin" },
    { key: 2, text: "Người dùng", value: "user" }
  ],

  PAYMENT_METHOD: [
    {
      value: 'TRANSFER',
      text: 'Chuyển khoản'
    },
    {
      value: 'CASH',
      text: 'Tiền mặt'
    },
    {
      value: 'OTHER',
      text: 'Khác'
    }
  ],
  SYSTEM_CODE: [
    { value: 'post', text: 'PCS Post', tooltip: 'Dịch vụ chuyển phát nhanh Nội địa', icon: Icons.system.system_2 },
    { value: 'app-post', text: 'App Mobile', tooltip: 'Ứng dụng chuyển phát nhanh Nội địa', icon: Icons.system.system_3 },
    { value: 'courier', text: 'PCS Courier', tooltip: 'Dịch vụ chuyển phát nhanh Quốc tế', icon: Icons.system.system_1 },
    { value: 'op', text: 'PCS OP', tooltip: 'Hệ thống vận hành chuyển phát', icon: Icons.system.system_4, private: true },
    { value: 'app-pu', text: 'App PU', tooltip: 'Ứng dụng vận hành cho nhân viên lấy hàng', icon: Icons.system.system_5, private: true },
    { value: 'sys', text: 'PCS SYS', tooltip: 'Hệ thống quản lý dịch vụ PCS', icon: Icons.system.system_6, private: true },
  ],
  LANGUAGES: [
    {
      code: 'vi',
      name: 'Tiếng Việt',
      flagIcon: Icons.flag.vi,
      icon: Icons.flag.vi_icon
    },
    // {
    //   code: 'en',
    //   name: 'English',
    //   flagIcon: Icons.flag.en,
    //   icon: Icons.flag.en_icon
    // },
    // {
    //   code: 'jp',
    //   name: 'Japan',
    //   flagIcon: Icons.flag.jp,
    //   icon: Icons.flag.jp_icon
    // },
  ]
}