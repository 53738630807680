import 'bootstrap/dist/css/bootstrap.css';
import 'moment/locale/vi';
import './styles/styles.less'
import './styles/styles.scss'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import store from './reduxs/store';
import history from './utils/history';
import viVN from 'antd/es/locale/vi_VN';
import moment from 'moment';
import i18n from "./i18n";
import userManager from './utils/userManager';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { ConfigProvider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { I18nextProvider } from "react-i18next";
import { OidcProvider } from 'redux-oidc';
moment.locale('vi-VN');
Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 30 }} spin />)
const Loader = () => <div className="loader"></div>;

ReactDOM.render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <ConnectedRouter history={history}>
        <ConfigProvider locale={viVN}>
          <I18nextProvider i18n={i18n}>
            <Suspense fallback={<Loader />}>
              <App />
            </Suspense>
          </I18nextProvider>
        </ConfigProvider>
      </ConnectedRouter>
    </OidcProvider>
  </Provider>,
  document.getElementById('root')
)
